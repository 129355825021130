body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100%;
}

.ant-modal-content {
  padding: 0 !important;
}

.ant-picker-panel {
  min-width: 180px;
}

.ant-picker-time-panel-cell-inner {
  display: flex !important;
  justify-content: center;
  margin: 5px auto !important;
  padding-inline-start: 0 !important;
  font-size: 18px;
}
.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background-color: #dec623 !important;
}

.ant-ribbon-wrapper {
  height: 100%;
}
